<template>
  <div id="sidenav" class="sidenav">
    <div id="imgNav" class="imgNav">
      <img src="@/assets/img/nav/sidenav.jpg" class="img-fluid" />
    </div>

    <div id="mnu" class="mnu">
      <nav class="tree-nav" style="padding: 0px">
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title parent-menu">
            <router-link :to="'/'" class="tree-nav__item-title"
              >Home
            </router-link>
          </summary>
        </details>

        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title parent-menu">
            Service Orders
          </summary>

          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Orders</summary>
            <div class="tree-nav__item">
              <router-link
                :to="'/service_orders_ov'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Orders</router-link
              >
              <router-link :to="'/uc'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Reports</router-link
              >
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Customers</summary>
            <div class="tree-nav__item">
              <router-link :to="'/customers_ov'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Overview</router-link
              >
            </div>
          </details>
        </details>

        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title parent-menu">Inventory</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Registration</summary>
            <div class="tree-nav__item">
              <router-link :to="'/products'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Items & Products</router-link
              >

              <router-link :to="'/categories'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Categories</router-link
              >
              <router-link :to="'/stock_locations'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Stock Locations</router-link
              >
              <router-link
                :to="'/material_recipes'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Recipes</router-link
              >
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Purchase</summary>
            <div class="tree-nav__item">
              <router-link
                :to="'/purchase_requisition'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> PR From</router-link
              >
              <router-link
                :to="'/purchase_requisition_ov'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> PR Overview</router-link
              >
            </div>
          </details>

          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Good Receive</summary>
            <div class="tree-nav__item">
              <!--router-link
                :to="'/good_receive_form/'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Good Receive Form</router-link
              -->
              <router-link
                :to="'/good_receive_form_2/'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Good Receive Form</router-link
              >
              <router-link :to="'/good_receive_ov'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Good Receive Overview</router-link
              >
              <router-link
                :to="'/finished_products_recieve'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Finished Products
                Receive</router-link
              >

              <router-link
                :to="'/imcoming_maretial_inspection'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Incoming Material
                Inspection</router-link
              >
            </div>
          </details>

          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Material Issue</summary>
            <details class="tree-nav__item is-expandable">
              <summary class="tree-nav__item-title">Issue Notes</summary>
              <div class="tree-nav__item">
                <router-link
                  :to="'/issue_entry/'"
                  class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> Issue Entry</router-link
                >
                <router-link
                  :to="'/issue_note_form_2/'"
                  class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> Issue Note Form</router-link
                >
                <router-link :to="'/issue_note_ov'" class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> Issue Overview</router-link
                >
                <router-link
                  :to="'/issue_note_reports'"
                  class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> Reports</router-link
                >
              </div>
            </details>
            <details class="tree-nav__item is-expandable">
              <summary class="tree-nav__item-title">Return Notes</summary>
              <div class="tree-nav__item">
                <router-link
                  :to="'/return_note_form/'"
                  class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> Return Note Form</router-link
                >
                <router-link
                  :to="'/return_note_ov'"
                  class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> Return Overview</router-link
                >
              </div>
            </details>
            <details class="tree-nav__item is-expandable">
              <summary class="tree-nav__item-title">
                Personal Equipment Issues
              </summary>
              <div class="tree-nav__item">
                <router-link
                  :to="'/personal_issue_note'"
                  class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> Personal Issue Form</router-link
                >
              </div>
            </details>
          </details>

          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Suppliers</summary>
            <div class="tree-nav__item">
              <router-link :to="'/suppliers'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Suppliers</router-link
              >
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Stock</summary>
            <details class="tree-nav__item is-expandable">
              <summary class="tree-nav__item-title">Balance</summary>
              <div class="tree-nav__item">
                <router-link :to="'/stock_balance'" class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> Balance</router-link
                >
                <router-link
                  :to="'/stock_balance_recipes'"
                  class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> Balance Recipes</router-link
                >
              </div>
            </details>
            <details class="tree-nav__item is-expandable">
              <summary class="tree-nav__item-title">Manage</summary>
              <div class="tree-nav__item">
                <router-link
                  :to="'/stock_transfer'"
                  class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> Transfer</router-link
                >
                <router-link :to="'/stock_entry'" class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> Manage</router-link
                >
              </div>
            </details>
          </details>
        </details>
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title parent-menu">Production</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Job Cards</summary>
            <div class="tree-nav__item">
              <router-link :to="'/jobcard'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Job Card Form</router-link
              >
            </div>
          </details>
        </details>

        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title parent-menu">
            Maintenance
          </summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Maintenance Sheets</summary>
            <div class="tree-nav__item">
              <router-link
                :to="'/maintenance_sheets'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Record List</router-link
              >
              <router-link :to="'/uc'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Maintanance Sheet</router-link
              >
              <router-link
                :to="'/maintenance_sheets_report'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Reports</router-link
              >
            </div>
          </details>
        </details>

        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title parent-menu">HR</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Employee</summary>
            <div class="tree-nav__item">
              <router-link :to="'/employees_ov'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Employee Names</router-link
              >
            </div>
          </details>
        </details>
        <details class="tree-nav__item is-expandable" v-if="AdminMenu">
          <summary class="tree-nav__item-title parent-menu">Admin</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Users</summary>
            <div class="tree-nav__item">
              <router-link :to="'/users'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Users</router-link
              >
              <router-link :to="'/users_logins'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Users Logins</router-link
              >
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Departments</summary>
            <div class="tree-nav__item">
              <router-link :to="'/departments'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Departments</router-link
              >
            </div>
          </details>

          <details class="tree-nav__item is-expandable" v-if="isSystem">
            <summary class="tree-nav__item-title">Admin</summary>
            <div class="tree-nav__item">
              <router-link :to="'/test'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Test Page</router-link
              >
            </div>
          </details>
        </details>
      </nav>
    </div>
    <div style="height: 20px"></div>
  </div>
</template>
<script>
import store from "@/store";
export default {
  setup() {},
  data() {
    return {
      AdminMenu: false,
      isAdmin: false,
      isSystem: false,
      role: null,
      isDivisionProduction: false,
      isDivistionSales: false,
      keyword: null,
      division: store.state.division,
    };
  },
  mounted() {
    this.checkSystem();
  },
  methods: {
    async checkSystem() {
      this.role = store.state.user.role;
      if (this.role == "System") {
        this.isSystem = true;
      }
      if (this.role == "System" || this.role == "Admin") {
        this.AdminMenu = true;
      }
    },
  },
};
</script>
<style scoped>
.sidenav {
  transition: 00.5s;
  width: 270px;
  left: 0;
  float: left;
  flex-wrap: wrap;
  height: 100vh;
  overflow: scroll;
  background-color: rgb(37, 37, 37);
  font-size: 0.9em;
  border-right: thin solid #5f5f5f;
}

.mImg {
  width: 100%;
}

.imgNav {
  height: auto;
}

.mnu {
  height: inherit;
}

.accordion-item:last-of-type,
.accordion-button.collapsed,
.accordion-item:first-of-type {
  border-radius: 0;
}

.parent-menu {
  background-color: rgb(49, 49, 49);
  border-bottom: #5f5f5f thin solid;
}
</style>
